
var date_m = '00'
var date_s = '00'
var date_ms = '00'
var setTimeoutId

//时间倒计时处理, end 后台拿到的结束时间戳
export function countTime (end, shuaxin, daoshijian) {

  //递归每秒调用countTime方法，显示动态时间效果
  setTimeoutId = setInterval(function (params) {

    var date = new Date()
    var now = date.getTime()
    var leftTime = end - now //时间差

    var d = 0, h = 0, m = 0, s = 0, ms = 0
    if (leftTime >= 700)
    {
      d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
      h = Math.floor(((leftTime / 1000 / 60 / 60) % 24) + 24 * d)
      m = Math.floor((leftTime / 1000 / 60) % 60)
      s = Math.floor((leftTime / 1000) % 60)
      ms = Math.floor(leftTime % 100)

    }
    else
    {
      // console.log('已截止')
      date_ms = '00'
      clearTimeout(setTimeoutId)
      console.log(date_ms)
      // 回调
      daoshijian()
      return
    }
    if (ms < 10)
    {
      ms = "0" + ms
    }
    if (s < 10)
    {
      s = "0" + s
    }
    if (m < 10)
    {
      m = "0" + m
    }

    //将倒计时赋值到div中
    // this.date_d = d //这里是渲染的天数
    // this.date_h = h //这里是渲染的小时
    date_m = m //这里是渲染的分钟
    date_s = s //这里是渲染的秒数
    date_ms = ms  // 这里是渲染的毫秒

    shuaxin(date_m, date_s, date_ms)
  }, 50)

}



